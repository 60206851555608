@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&family=Castoro:ital@0;1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  overflow-x: hidden !important;
}



.select-container {
  position: relative;
}

.select-container select {
  -webkit-appearance: none;  /* Remove default arrow in WebKit browsers */
  -moz-appearance: none;     /* Remove default arrow in Firefox */
  appearance: none;          /* Remove default arrow from modern browsers */
  /* Additional styling */
}

/* Custom arrow */
.select-container:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px; /* Adjust as needed */
  transform: translateY(-50%);
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* Adjust arrow color as needed */
  pointer-events: none; /* Ignore events */
}
